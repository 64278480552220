<template>
  <div class="animated fadeIn">
    <b-card>
      <h4><i class="fa fa-search"></i> Search Invoice</h4>
      <hr />
      <b-collapse v-model="searchContainer" id="searchCollapse">
        <b-row class="mb-2">
          <b-col md="4">
            <label for="">RTNest Invoice Ref. Number</label>
            <b-input-group class="mb-2">
              <b-form-input type="text" name="tracking" v-model="invnum" class="form-control"
                placeholder="RTNest Invoice Ref. Number" />
            </b-input-group>
          </b-col>
          <div class="col-md-4">
            <label for="">Project</label>
            <select class="select-account form-control" v-model="projectidSearch">
              <option v-for="option in projectData" v-bind:value="option.projectid">
                {{ option.projectname }}
              </option>
            </select>
          </div>
          <b-col md="4">
            <label for="">Vendor Company Name</label>
            <b-input-group class="mb-2">
              <b-form-input type="text" name="vendor" v-model="vendor_company_name" class="form-control"
                placeholder="Vendor Company Name" />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <label for="">AFE / PO Number</label>
            <b-input-group class="mb-2">
              <b-input type="text" name="afe" v-model="afe" class="form-control" placeholder="AFE Number" />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <label for="">Cost Code #1 / Major</label>
            <b-input-group class="mb-2">
              <b-form-input type="text" name="cc1" v-model="cc1" class="form-control" placeholder="Cost Code 1" />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <label for="">Cost Code #2 / Minor</label>
            <b-input-group class="mb-2">
              <b-form-input type="text" name="cc2" v-model="cc2" class="form-control" placeholder="Cost Code 2" />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <label for="">Cost Code #3 / Description</label>
            <b-input-group class="mb-2">
              <b-form-input type="text" name="cc3" v-model="cc3" class="form-control" placeholder="Cost Code 3" />
            </b-input-group>
          </b-col>
          <b-col md="4">
            <label for="">Invoice Starting Date - Today</label>
            <b-input-group class="mb-2">
              <datepicker class="date-picker rt-datepicker" v-model="date" placeholder="Start Date"></datepicker>
            </b-input-group>
          </b-col>
          <b-col md="6">
            <label for="">Search</label>
            <b-input-group class="mb-2">
              <b-button @click="search()" variant="success" class="mr-3">
                Search <i class="fa fa-search"></i>
              </b-button>
              <b-button @click="clear()" variant="danger">
                Clear <i class="fa fa-times"></i>
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
      </b-collapse>
      <b-row>
        <b-col class="col-md-12">
          <b-btn v-b-toggle.searchCollapse class="mt-1 btn-blue">
            <span class="when-opened">Close Advanced Search <i class="fa fa-chevron-up"></i></span>
            <span class="when-closed">Open Advanced Search <i class="fa fa-chevron-down"></i></span>
          </b-btn>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <h4 class="d-inline"><i class="nav-icon icon-layers"></i> Invoices</h4>

      <hr class="clearfix my-3" />
      <div class="row mb-3">
        <div class="col-md-4 mb-2">
          <h5>Select Project To Get Invoices</h5>
          <v-select :clearable="false" class="project-select" v-model="projectid" label="projectname"
            :options="projectData" @input="loadInvoices()"></v-select>
        </div>

        <div class="col-9 col-md-6">
          <h5>AP Team Review / Paid</h5>
          <v-select :clearable="false" class="project-select" v-model="type" label="type" :options="typeOptions"
            @input="loadInvoices()"></v-select>
        </div>
        <div v-if="type" class="col-3 col-md-4">
          <h5>&nbsp;</h5>
          <button v-b-modal.export class="btn btn-primary mr-2" @click="clearLabels()">
            Export Invoice Report <i class="fa fa-cog"></i>
          </button>
          <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary"
            class="d-inline-block">
            <button @click="loadInvoices()" class="btn btn-primary">
              <i class="fa fa-sync-alt"></i>
            </button>
          </b-overlay>
        </div>
      </div>

      <div class="row mt-4 mb-4">
        <div class="col-md-12">
          <!-- <vue-json-to-csv
            v-if="$refs.invoicetable"
            :labels="labels"
            :csv-title="today"
            class="btn mr-3 btn-sm btn-blue"
            :json-data="sendFiltered()"
          >
            Download Table CSV/Excel
            <i class="fa fa-download"></i>
          </vue-json-to-csv> -->
          <b-modal hide-footer id="export" ref="export-invoice-report" title="Export CSV Options" title-tag="h2"
            centered size="lg">
            <div class="row">
              <div class="col-md-6">
                <h4>Vendor</h4>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['vcn', 'Vendor Company Name']"
                    v-model="customLabel" />
                  <label class="form-check-label"><strong>Company Name</strong></label>
                </div>
                <!-- <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="['gst', 'GST Number']"
                    v-model="customLabel"
                  />
                  <label class="form-check-label">GST Number</label>
                </div> -->
                <!-- <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="['rcn', 'Registration Number']"
                    v-model="customLabel"
                  />
                  <label class="form-check-label">Registration Number</label>
                </div> -->
                <!-- <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="['bankaccount', 'Bank Account']"
                    v-model="customLabel"
                  />
                  <label class="form-check-label">Bank Account</label>
                </div> -->
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['addressline1', 'Vendor Address Line 1']"
                    v-model="customLabel" />
                  <label class="form-check-label">Address Line 1</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['addressline2', 'Vendor Address Line 2']"
                    v-model="customLabel" />
                  <label class="form-check-label">Address Line 2</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['city', 'City']" v-model="customLabel" />
                  <label class="form-check-label">City</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['province', 'Province']"
                    v-model="customLabel" />
                  <label class="form-check-label">Province</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['postalcode', 'Postal Code']"
                    v-model="customLabel" />
                  <label class="form-check-label">Postal Code</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['phone_num', 'Phone Number']"
                    v-model="customLabel" />
                  <label class="form-check-label">Phone Number</label>
                </div>
              </div>
              <div class="col-md-6">
                <h4>Invoice</h4>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['invnum', 'RTNest Invoice Number']"
                    v-model="customLabel" />
                  <label class="form-check-label">RTNest Invoice Number</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['inv_passed', 'Invoice Status']"
                    v-model="customLabel" />
                  <label class="form-check-label">Invoice Status</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['ticketnum', 'Field Ticket Number']"
                    v-model="customLabel" />
                  <label class="form-check-label">Field Ticket Number</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                    :value="['vendor_invoice_number', 'Vendor Invoice Number']" v-model="customLabel" />
                  <label class="form-check-label">Vendor Invoice Number</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['creation_date', 'Invoice Date']"
                    v-model="customLabel" />
                  <label class="form-check-label">Invoice Date</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['total', 'Total (Including GST)']"
                    v-model="customLabel" />
                  <label class="form-check-label">Total (Including GST)</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['tax_percentage', 'GST']"
                    v-model="customLabel" />
                  <label class="form-check-label">GST</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="[
                    'quickpay_total',
                    'Quick Pay Total (Including GST)',
                  ]" v-model="customLabel" />
                  <label class="form-check-label">Quick Pay Total (Including GST)</label>
                </div>
                <!-- <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="['gst_quickpay_total', 'Quick Pay GST']"
                    v-model="customLabel"
                  />
                  <label class="form-check-label">Quick Pay GST</label>
                </div> -->
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="['qbyte_status', 'Qbyte Status']"
                    v-model="customLabel" />
                  <label class="form-check-label">Qbyte Status</label>
                </div>
                <!-- <hr />
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    @input="seperateLines()"
                    v-model="customAfe"
                  />
                  <label class="form-check-label"
                    >List of AFEs &amp; Cost Codes</label
                  > -->
                <!-- </div> -->
              </div>
            </div>
            <div class="invoice-report mt-3">
              <h5>&nbsp;</h5>
              <b-overlay :show="emailLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
                <button class="btn btn-primary btn-sm" @click="getWeeklyReport(customLabel)">
                  {{
                      emailLoading
                        ? 'Downloading Invoice Report...'
                        : 'Download Invoice Report'
                  }}
                  <i class="fa fa-download mr-1"></i>
                </button>
              </b-overlay>
            </div>
            <!-- <vue-json-to-csv
              v-if="$refs.invoicetable && customAfe"
              :labels="customLabels"
              :csv-title="today"
              class="btn mt-3 btn-sm btn-blue"
              :json-data="customFiltered"
            >
              Download Table CSV/Excel (AFE)
              <i class="fa fa-download"></i>
            </vue-json-to-csv>
            <vue-json-to-csv
              v-if="$refs.invoicetable && !customAfe"
              :labels="customLabels"
              :csv-title="today"
              class="btn mt-3 btn-sm btn-blue"
              :json-data="sendFiltered()"
            >
              Download Table CSV/Excel
              <i class="fa fa-download"></i>
            </vue-json-to-csv> -->
          </b-modal>
        </div>
      </div>

      <div class="row mb-3" v-if="checkedRows.length > 0" style="
          background: #5d5d5d;
          border-radius: 4px;
          padding: 1em;
          margin-right: 0 !important;
          margin-left: 0 !important;
        ">
        <div class="col-12" style="color: #fff">
          <h3 style="color: rgb(255, 255, 255); margin-top: 5px; margin-bottom: 0">
            Selected Total (GST Included): ${{ formatPrice(subtotal) }}
          </h3>
        </div>
        <div class="col-12" style="color: #fff" v-if="bankaccounts.length > 0">
          <h5 class="mt-2 mb-0">Bank Accounts:</h5>
          <p class="mb-1 mt-0" v-for="vendor in bankaccounts">
            <strong>{{ vendor.vcn }}</strong>: {{ vendor.bankaccount }}
          </p>
        </div>

        <div class="col-12 col-md-6 col-lg-6 mt-3 mb-2">
          <button type="button" @click="loadServiceEstimates()" class="btn btn-info btn-lg"
            style="background: #b731ea; border-color: #b731ea; color: #fff">
            <i class="fa fa-binoculars"></i> Review Line-item Estimate Costs vs
            Actual Cost Per AFE
          </button>
        </div>
      </div>
      <v-client-table v-on:filter="clearSelected()" id="dataTable" class="inv-status-table table-responsive acc-table"
        :columns="columns" :data="invoiceData" ref="invoicetable" :options="options" :theme="theme">
        <span slot="total" slot-scope="props">
          ${{ formatPrice(props.row.total) }}
        </span>

        <div slot="quickpay_total" slot-scope="props">
          <span v-if="
            props.row.quickpay_total > 0 &&
            $moment(props.row.endtday) > $moment()
          ">
            {{ formatPrice(props.row.quickpay_total) }}
          </span>
        </div>

        <span slot="invoice" slot-scope="props"><a href="/#/wss/invoice">{{ props.row.invoice }}</a> ({{
            props.row.company
        }})</span>
        <input slot="check" v-if="props.row.accountant_paid === 0" slot-scope="props" type="checkbox" :value="{
          id: props.row.invoiceid,
          projectid: props.row.projectid,
          quickpay_total: props.row.quickpay_total,
          total: props.row.total,
          endtday: props.row.endtday,
          bankaccount: props.row.bankaccount,
          vcn: props.row.vcn,
        }" class="checkedInvoices rt-checkbox-table" v-model="checkedRows" />
        <div slot="inv_passed" slot-scope="props">
          <div v-if="props.row.accountant_paid === 0">
            <div v-if="props.row.inv_passed === 9">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By WSS (Submit Again)
              </button>
            </div>
            <div v-if="props.row.inv_passed === 17">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Accontant
              </button>
            </div>
            <div v-if="props.row.inv_passed === 8 && props.row.acc_project != 1">
              <button class="btn-sm btn-warning btn-pm">
                <i class="fa fa-question"></i> Pending By Project Manager
              </button>
            </div>
            <div v-if="props.row.inv_passed === 8 && props.row.acc_project == 1">
              <button class="btn-sm btn-warning btn-pm" style="
                  border-color: #24cabf !important;
                  background: #24cabf !important;
                ">
                <i class="fa fa-question"></i> Pending For First Approval
              </button>
            </div>

            <div v-if="props.row.inv_passed === 7">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 6">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By Higher Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 5">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Project Manager (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 0">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By WSS
              </button>
            </div>

            <!-- <div v-if="props.row.inv_passed === 2 ">
              <button class="btn-sm btn-blue"><i class="fa fa-save"></i> Draft</button>
            </div> -->

            <div v-if="props.row.inv_passed === 11">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 13">
              <button class="btn-sm btn-warning btn-management">
                <i class="fa fa-question"></i> Pending By Higher Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 14">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 21">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 23">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 24">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 31">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 33">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 34">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 41">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 43">
              <button class="btn-sm btn-warning">
                <i class="fa fa-question"></i> Pending By Management
              </button>
            </div>

            <div v-if="props.row.inv_passed === 44">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>

            <div v-if="props.row.inv_passed === 51">
              <button class="btn-sm btn-success">
                <i class="fa fa-check"></i> AP Team Review
              </button>
            </div>

            <div v-if="props.row.inv_passed === 54">
              <button class="btn-sm btn-danger">
                <i class="fa fa-times"></i> Rejected By Management (Pending
                Vendor)
              </button>
            </div>
          </div>
          <div v-if="props.row.accountant_paid === 1">
            <button class="btn-sm btn-success">
              <i class="fa fa-check"></i> Paid
            </button>
          </div>
        </div>
        <div slot="creation_date" slot-scope="props">
          {{ $moment(props.row.creation_date).format('DD/MM/YYYY') }}
        </div>
        <div slot="vfname" slot-scope="props">
          {{ props.row.vfname }} {{ props.row.vlname }}
        </div>

        <div slot="invnum" slot-scope="props">
          <a href="javascript:void(0)" v-if="
            props.row.inv_passed == 7 ||
            props.row.accountant_paid === 1 ||
            props.row.inv_passed == 11 ||
            props.row.inv_passed == 6 ||
            props.row.inv_passed == 21 ||
            props.row.inv_passed == 31 ||
            props.row.inv_passed == 41 ||
            props.row.inv_passed == 51
          " @click="setTheInvoice(props.row, 2)">{{ props.row.invnum }}</a>
          <a href="javascript:void(0)" v-else @click="setTheInvoice(props.row, 1)">{{ props.row.invnum }}</a>
        </div>

        <div slot="actions" slot-scope="props">
          <button v-b-modal.history @click="sendHistory(props.row)" class="btn white btn-sm btn-secondary">
            <i class="fa fa-history"></i>
          </button>
        </div>
      </v-client-table>

      <b-modal ok-only id="history" ref="history" :title="'Invoice #' + history.invnum" title-tag="h2" centered
        size="xl">
        <InvoiceHistory v-bind:history="history" v-bind:isMounted="true"></InvoiceHistory>
      </b-modal>
      <b-modal ok-only id="serviceEstimates" ref="serviceEstimates" :title="'Service Estimates'" title-tag="h2" centered
        size="xl">
        <div class="row">
          <div class="col-md-6 col-xl-6 mt-1">
            <h4>Select AFE / PO</h4>
            <multiselect @input="getServices()" v-model="afe" placeholder="Search or add afe / po"
              tag-placeholder="Search or add afe / po" track-by="afenum" :options="afesList" :multiple="false"
              :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="false" label="afenum"
              :preselect-first="true">
            </multiselect>
          </div>
        </div>
        <div class="col-12 my-4" v-for="vendor in groupedServices">
          <h3>{{ vendor.name }}</h3>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <td style="vertical-align: middle; font-size: 1.8em">
                    <strong>Totals</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>Estimate Total:</strong><br />${{
                        formatPrice(vendor.estimate)
                    }}
                  </td>
                  <td>
                    <strong>Actual Total:</strong><br />${{
                        formatPrice(vendor.subtotal)
                    }}
                  </td>
                  <td>
                    <strong>Total Net:</strong><br /><span :class="{
                      green: vendor.estimate > vendor.subtotal,
                      red: vendor.estimate < vendor.subtotal,
                    }">${{
    formatPrice(vendor.estimate - vendor.subtotal)
}}</span>
                  </td>
                </tr>
                <tr>
                  <th>Service Name</th>
                  <th>UOM 1</th>
                  <th>UOM 2</th>
                  <th>UOM 3</th>
                  <th>Total Actual Estimate</th>
                  <th>Total Actual Cost</th>
                  <th>Net</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="service in vendor.code">
                  <td>{{ service.name }}</td>
                  <!-- UOM1 -->
                  <td>
                    <strong>Total Actual Quantity</strong>:
                    {{ service.groupedUom[0].totalQuantity }}<br />
                    <strong>Latest P/U</strong>: ${{
                        service.groupedUom[0].latestPu
                    }}
                    ({{ service.groupedUom[0].name }})<br />
                    <div v-if="service.groupedUom[0].estimateQuantity1">
                      <strong class="my-2 d-block">Total Estimate Quantity:</strong>
                      {{ formatPrice(service.groupedUom[0].estimateQuantity1) }}
                      <strong class="my-2 d-block">Total Estimate Cost:</strong>
                      ${{
                          formatPrice(service.groupedUom[0].totalEstimateCost1)
                      }}
                    </div>
                    <div v-else>$0.00</div>
                  </td>
                  <!-- UOM2 -->
                  <td v-if="service.groupedUom[1]">
                    <strong>Total Actual Quantity</strong>:
                    {{ service.groupedUom[1].totalQuantity }}<br />
                    <strong>Latest P/U</strong>: ${{
                        service.groupedUom[1].latestPu
                    }}
                    ({{ service.groupedUom[1].name }})<br />
                    <div v-if="service.groupedUom[1].estimateQuantity2">
                      <strong class="my-2 d-block">Total Estimate Quantity:</strong>
                      {{ formatPrice(service.groupedUom[1].estimateQuantity2) }}
                      <strong class="my-2 d-block">Total Estimate Cost:</strong>
                      ${{
                          formatPrice(service.groupedUom[1].totalEstimateCost2)
                      }}
                    </div>
                    <div v-else>$0.00</div>
                  </td>
                  <td v-else>N/A</td>
                  <!-- UOM3 -->
                  <td v-if="service.groupedUom[2]">
                    <strong>Total Actual Quantity</strong>:
                    {{ service.groupedUom[2].totalQuantity }}<br />
                    <strong>Latest P/U</strong>: ${{
                        service.groupedUom[2].latestPu
                    }}
                    ({{ service.groupedUom[2].name }})<br />
                    <div v-if="service.groupedUom[2].estimateQuantity3">
                      <strong class="my-2 d-block">Total Estimate Quantity:</strong>
                      {{ formatPrice(service.groupedUom[2].estimateQuantity3) }}
                      <strong class="my-2 d-block">Total Estimate Cost:</strong>
                      ${{
                          formatPrice(service.groupedUom[2].totalEstimateCost3)
                      }}
                    </div>
                    <div v-else>$0.00</div>
                  </td>
                  <td v-else>N/A</td>
                  <td>
                    <div v-if="
                      service.groupedUom[0] &&
                      service.groupedUom[0].estimateQuantity1 &&
                      service.groupedUom[1] &&
                      service.groupedUom[1].estimateQuantity1 &&
                      !service.groupedUom[2]
                    ">
                      ${{
                          formatPrice(
                            service.groupedUom[0].latestPu *
                            service.groupedUom[0].estimateQuantity1 +
                            service.groupedUom[1].latestPu *
                            service.groupedUom[1].estimateQuantity2
                          )
                      }}
                    </div>
                    <div v-if="service.groupedUom[1] && service.groupedUom[2]">
                      ${{
                          formatPrice(
                            service.groupedUom[0].latestPu *
                            service.groupedUom[0].estimateQuantity1 +
                            service.groupedUom[1].latestPu *
                            service.groupedUom[1].estimateQuantity2 +
                            service.groupedUom[2].latestPu *
                            service.groupedUom[2].estimateQuantity3
                          )
                      }}
                    </div>
                    <div v-if="
                      service.groupedUom[0] &&
                      service.groupedUom[0].estimateQuantity1 &&
                      !service.groupedUom[1] &&
                      !service.groupedUom[2]
                    ">
                      ${{
                          formatPrice(
                            service.groupedUom[0].latestPu *
                            service.groupedUom[0].estimateQuantity1
                          )
                      }}
                    </div>
                  </td>
                  <td>${{ formatPrice(service.total) }}</td>

                  <td>
                    <div v-if="
                      service.groupedUom[0] &&
                      !service.groupedUom[0].estimateQuantity1
                    ">
                      $0.00
                    </div>
                    <div v-else>
                      <div class="green" v-if="service.groupedUom[1] && !service.groupedUom[2]" :class="{
                        danger:
                          service.groupedUom[0].latestPu *
                          service.groupedUom[0].estimateQuantity1 +
                          service.groupedUom[1].latestPu *
                          service.groupedUom[1].estimateQuantity2 -
                          service.total <
                          0,
                      }">
                        ${{
                            formatPrice(
                              service.groupedUom[0].latestPu *
                              service.groupedUom[0].estimateQuantity1 +
                              service.groupedUom[1].latestPu *
                              service.groupedUom[1].estimateQuantity2 -
                              service.total
                            )
                        }}
                      </div>
                      <div class="green" v-if="service.groupedUom[1] && service.groupedUom[2]" :class="{
                        danger:
                          service.groupedUom[0].latestPu *
                          service.groupedUom[0].estimateQuantity1 +
                          service.groupedUom[1].latestPu *
                          service.groupedUom[1].estimateQuantity2 +
                          service.groupedUom[2].latestPu *
                          service.groupedUom[2].estimateQuantity3 -
                          service.total <
                          0,
                      }">
                        ${{
                            formatPrice(
                              service.groupedUom[0].latestPu *
                              service.groupedUom[0].estimateQuantity1 +
                              service.groupedUom[1].latestPu *
                              service.groupedUom[1].estimateQuantity2 +
                              service.groupedUom[2].latestPu *
                              service.groupedUom[2].estimateQuantity3 -
                              service.total
                            )
                        }}
                      </div>
                      <div class="green" v-if="
                        service.groupedUom[0] &&
                        service.groupedUom[0].estimateQuantity1 &&
                        !service.groupedUom[1] &&
                        !service.groupedUom[2]
                      " :class="{
  danger:
    service.groupedUom[0].latestPu *
    service.groupedUom[0].estimateQuantity1 -
    service.total <
    0,
}">
                        ${{
                            formatPrice(
                              service.groupedUom[0].latestPu *
                              service.groupedUom[0].estimateQuantity1 -
                              service.total
                            )
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import { ClientTable } from 'vue-tables-2';
import Datepicker from 'vuejs-datepicker';
import VueJsonToCsv from 'vue-json-to-csv';
import InvoiceHistory from './../invoice/InvoiceHistory';
import Multiselect from 'vue-multiselect';
Vue.use(ClientTable);

export default {
  name: 'manager',
  components: {
    ClientTable,
    Datepicker,
    VueJsonToCsv,
    InvoiceHistory,
    Multiselect,
  },
  data() {
    return {
      columns: [
        'check',
        'inv_passed',
        'invnum',
        'ticketnum',
        'vendor_invoice_number',
        'vcn',
        'vfname',
        'creation_date',
        'total',
        'quickpay_total',
        'actions',
      ],
      today: '',
      customTest: [],
      inv_status: [0, 8, 9, 6, 5, 13, 43, 44, 54],
      options: {
        initFilters: {
          GENERIC: this.$session.get('query'),
        },
        orderBy: this.$session.get('orderBy'),
        initialPage: this.$session.get('page'),
        //initialPage: this.$session.get('page'),
        headings: {
          check: function (h) {
            return h('input', {
              attrs: {
                type: 'checkbox',
                id: 'selectAllCheckbox',
              },
              on: {
                click: (e) => {
                  this.selectAllInTable();
                },
              },
              ref: 'selectAllCheckbox',
            });
          },
          inv_passed: 'Status',
          invnum: 'Invoice #',
          creation_date: 'Submitted Date (DD/MM/YYYY)',
          amount: 'Amount',
          vcn: 'Company Name',
          vfname: 'Vendor Name',
          quickpay_total: 'Quickpay Total',
          ticketnum: 'Field Ticket #',
          vendor_invoice_number: 'Vendor Invoice #',
        },
        oLanguage: {
          sSearch: 'your-text-here',
        },
        sortable: [
          'inv_passed',
          'invnum',
          'vcn',
          'vfname',
          'creation_date',
          'total',
          'quickpay_total',
        ],
        filterable: [
          'invnum',
          'ticketnum',
          'vendor_invoice_number',
          'vcn',
          'creation_date',
          'vfname',
          'total',
          'quickpay_total',
        ],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-up',
          down: 'fa-sort-down',
          is: 'fa-sort',
        },
        perPage: 10,
        texts: {
          filterPlaceholder: 'Search By Vendor',
        },
        // pagination: {
        // 	nav: 'scroll',
        //   chunk: 40
        // },
      },
      searchContainer: false,
      cc: '',
      afe: '',
      history: [],
      invoiceData: [],
      date: '',
      tracking: '',
      vendor: '',
      type: {
        type: 'AP Team Review',
      },
      typeOptions: [
        {
          type: 'All',
        },
        {
          type: 'AP Team Review',
        },
        {
          type: 'Paid',
        },
        {
          type: 'Processed',
          id: 'processed_invoices',
        },
        {
          type: 'Unprocessed By PM',
          id: 'unprocessed_invoices',
        },
        {
          type: 'Unprocessed By WSS',
          id: 'unprocessed_invoices_wss',
        },
        {
          type: 'Not Ready For Payment',
          id: 'not_ready_for_payment',
        },
      ],
      projectData: [],
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      projectid: {},
      checkedRows: [],
      afe: '',
      cc1: '',
      cc2: '',
      cc3: '',
      invnum: '',
      date: '',
      vendor_company_name: '',
      projectidSearch: 0,
      windowWidth: 0,
      windowHeight: 0,
      labels: {
        vcn: { title: 'Company Name' },
        gst: { title: 'GST Number' },
        rcn: { title: 'Registration Number' },
        bankaccount: { title: 'Bank Account' },
        addressline1: { title: 'Address Line 1' },
        addressline2: { title: 'Address Line 2' },
        city: { title: 'City' },
        province: { title: 'Province' },
        postalcode: { title: 'Postal Code' },
        phone_num: { title: 'Phone Number' },
        invnum: { title: 'Invoice Number' },
        ticketnum: { title: 'Field Ticket Number' },
        vendor_invoice_number: { title: 'Vendor Invoice Number' },
        creation_date: { title: 'Creation Date' },
        total: { title: 'Total (Including GST)' },
        tax_percentage: { title: 'GST' },
        quickpay_total: { title: 'Quick Pay Total (Including GST)' },
        gst_quickpay_total: { title: 'Quick Pay GST' },
      },
      customLabel: [
        ['vcn', 'Vendor Company Name'],
        ['invnum', 'RTNest Invoice Number'],
        // ['ticketnum', 'Field Ticket Number'],
        ['vendor_invoice_number', 'Vendor Invoice Number'],
        ['creation_date', 'Invoice Date'],
        ['total', 'Total (Including GST)'],
        // ['tax_percentage', 'GST'],
        ['quickpay_total', 'Quick Pay Total (Including GST)'],
        ['inv_passed', 'Invoice Status'],
        ['qbyte_status', 'Qbyte Status'],
        // ['gst_quickpay_total', 'Quick Pay GST'],
      ],
      customFiltered: [],
      invoiceData3: [],
      invoiceData4: [],
      customAfe: false,
      chequeDate: '',
      chequeNumber: '',
      comments: '',
      markedRows: [],
      allMarked: false,
      afesList: [],
      afe: [],
      serviceData: [],
      serviceList: [],
      usersName: '',
      isLoading: true,
      emailLoading: false,
    };
  },
  methods: {
    search() {
      this.checkedRows = [];
      if (this.date !== '') {
        this.date = this.$moment(this.date).format('YYYY-MM-DD');
      }
      const selected_projects =
        this.projectidSearch === 0
          ? this.projectData.map((val) => val.projectid)
          : [this.projectidSearch];
      let data = JSON.stringify({
        afe: this.afe,
        cc1: this.cc1,
        cc2: this.cc2,
        cc3: this.cc3,
        invnum: this.invnum,
        date: this.date,
        vendor_company_name: this.vendor_company_name,
        projectid: selected_projects,
        role: 'accountant',
      });

      this.$axios
        .post('/manager/search/invoice', data)
        .then((response) => {
          var invoiceData = response.data.result;
          this.loadSearchInvoices(invoiceData);
        })
        .catch((error) => { });
    },
    loadSearchInvoices(invoiceData) {
      this.invoiceData = [];
      const params = {};
      params.invoiceid = invoiceData.map((val) => val.invoiceid).join(',');
      this.$axios
        .get('/manager/get/invoice/invNum', { params })
        .then((response) => {
          this.error = true;
          this.invoiceData = response.data.result;
        })
        .catch((error) => {
          this.$toasted.show('Error Fetching Search Invoices.', {
            type: 'error',
            duration: '3000',
          });
        });
    },

    async setTheInvoice(props, setInvoice) {
      let invoiceData = await this.getInvoice(props.invoiceid);
      invoiceData = invoiceData.data.result;
      if (setInvoice == 1) {
        this.setInvoice(invoiceData, setInvoice, this.$refs.invoicetable);
        this.$router.push({
          name: 'Accountant Invoices',
          path: '/accountant/invoices',
          params: { invoice: invoiceData, set: setInvoice, role: 'Accountant' },
        });
      } else {
        this.setInvoice(invoiceData, setInvoice, this.$refs.invoicetable);
        this.$router.push({
          name: 'Accountant Invoice',
          path: '/accountant/invoice',
          params: { invoice: invoiceData, set: setInvoice, role: 'Accountant' },
        });
      }
    },
    loadServiceEstimates() {
      this.afeList = [];
      this.serviceList = [];

      for (var i = 0; i < this.checkedRows.length; i++) {
        this.$http
          .get(
            '/manager/get/invoice_services/' +
            this.checkedRows[i].projectid +
            '/' +
            this.checkedRows[i].id
          )
          .then((response) => {
            this.afesList = this.afesList.concat(response.data.result);
            this.serviceList = this.serviceList.concat(response.data.result);
            this.afesList = this.lodash.uniqBy(this.afesList, 'afenum');

            this.$refs.serviceEstimates.show();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getServices() {
      this.serviceData = [];
      this.$axios
        .get(
          '/service_estimate/get/' + this.afe.projectid + '/' + this.afe.pwcaid
        )
        .then((response) => {
          var result = this.lodash.uniqBy(response.data.result, 'apid');

          // const intersectServices = result.reduce((accum, role) => {
          //   const service = this.serviceList.find(({ serviceid }) => role.serviceid === serviceid);
          //   if (service) accum.push({ ...role, ...service });
          //   return accum;
          // }, []);

          var temp = [];

          for (var i = 0; i < this.serviceList.length; i++) {
            if (
              result.filter((x) => x.serviceid == this.serviceList[i].serviceid)
                .length > 0 &&
              temp.filter((x) => x.serviceid == this.serviceList[i].serviceid)
                .length == 0
            ) {
              var tempPush = result.filter(
                (x) => x.serviceid == this.serviceList[i].serviceid
              );
              for (var x = 0; x < tempPush.length; x++) {
                temp.push(tempPush[x]);
              }
            }
          }

          this.serviceData = temp.filter((x) => x.afenum == this.afe.afenum);
        });
    },
    clearSelected() {
      this.checkedRows = [];
    },
    selectAllInTable() {
      this.checkedRows = [];
      this.allMarked = !this.allMarked;

      if (this.allMarked) {
        for (
          var i = 0;
          i < this.$refs.invoicetable.allFilteredData.length;
          i++
        ) {
          this.checkedRows.push({
            id: this.$refs.invoicetable.allFilteredData[i].invoiceid,
            projectid: this.$refs.invoicetable.allFilteredData[i].projectid,
            quickpay_total: this.$refs.invoicetable.allFilteredData[i]
              .quickpay_total,
            total: this.$refs.invoicetable.allFilteredData[i].total,
            endtday: this.$refs.invoicetable.allFilteredData[i].endtday,
            bankaccount: this.$refs.invoicetable.allFilteredData[i].bankaccount,
            vcn: this.$refs.invoicetable.allFilteredData[i].vcn,
          });
        }
      } else {
        this.checkedRows = [];
      }
    },
    markAll() {
      this.markAllInvoices().then((data) => {
        this.$toasted.show('Invoices Updated Successfully', {
          type: 'success',
          duration: '3000',
        });
        this.loadInvoices();
      });
    },
    markAllInvoices() {
      return new Promise((resolve, reject) => {
        let data = JSON.stringify();

        var comments = this.usersName;
        if (this.comments != '') {
          comments += ': ' + this.comments;
        }

        const promises = this.checkedRows.map((record) =>
          this.$http.post('/accountant/edit/invoice', {
            invoiceid: record.id,
            projectid: record.projectid,
            accountant_paid: 1,
            ac_note: comments,
            status: 1,
            check_round_date: this.chequeDate,
            check_number: this.chequeNumber,
          })
        );

        Promise.all(promises) // creates single promise that resolves when all `promises` resolve
          .then((responses) => {
            const dataArray = responses.map((response) => response.data.result);
            return resolve(dataArray);
          }) // resolves with an array of response data
          .catch(reject);
      });
    },
    getInvoiceReportParams(header) {
      const object = Object.fromEntries(header);
      const keys = Object.keys(object);
      const headers = Object.values(object);
      return [keys, headers];
    },
    genrateCsv(string) {
      var a = document.createElement('a');
      a.href = 'data:attachment/csv,' + encodeURIComponent(string);
      a.target = '_blank';
      a.download = 'RTNest Invoice Report.csv';
      document.body.appendChild(a);
      a.click();
    },
    async getWeeklyReport(header) {
      try {
        this.emailLoading = true;
        const [keys, headers] = this.getInvoiceReportParams(header);
        const response = await this.$axios.get('/wts/get/weekly/report/', {
          params: {
            keys: keys,
            headers: headers,
            hasQbyteStatus: keys.includes('qbyte_status'),
          },
        });
        this.genrateCsv(response.data.result);
        this.$refs['export-invoice-report'].hide();
        this.emailLoading = false;
        this.$toasted.show('Invoice Report Exported.', {
          type: 'success',
          duration: '3000',
        });
      } catch (err) {
        this.emailLoading = false;
        this.$toasted.show('Error Exporting Invoice Reprot.', {
          type: 'error',
          duration: '3000',
        });
      }
    },
    clearLabels() {
      this.customFiltered = [];
      this.customAfe = false;
      this.emailLoading = false;
      this.customLabel = [
        ['vcn', 'Vendor Company Name'],
        ['invnum', 'RTNest Invoice Number'],
        // ['ticketnum', 'Field Ticket Number'],
        ['vendor_invoice_number', 'Vendor Invoice Number'],
        ['creation_date', 'Invoice Date'],
        ['total', 'Total (Including GST)'],
        // ['tax_percentage', 'GST'],
        // ['quickpay_total', 'Quick Pay Total (Including GST)'],

        // ['gst_quickpay_total', 'Quick Pay GST'],
        ['inv_passed', 'Invoice Status'],
        ['qbyte_status', 'Qbyte Status'],
      ];
    },
    async getAccountantInvoices(invType, project) {
      const params = {};
      if (invType) {
        params.invType = invType;
      }
      if (project) {
        params.project = project;
      }
      return this.$http.get('/accountant/get/inv', { params }).then((res) =>
        res.data.result.map((val) => {
          return {
            ...val,
            ...(val.creation_date && {
              creation_date: val.creation_date,
            }),
          };
        })
      );
    },
    async getWtsInvoices(invType, project, invPassed, filterProcessed) {
      console.log("invType : ",invType);
      const params = {};
      if (invType) {
        params.invType = invType;
      }else {
        params.invType = 'AP Team Review';
      }
      if (project) {
        params.project = project;
      }
      return this.$http.get('/wts/get/inv', { params }).then((res) =>
        res.data.result
          .map((val) => {
            return {
              ...val,
              ...(val.creation_date && {
                creation_date: val.creation_date,
              }),
            };
          })
          .reverse()
          .filter((x) => {
            if (invPassed === null) {
              return true;
            }
            if (invPassed === false) {
              return x.inv_passed == 0;
            }

            if (invPassed === true) {
              return x.inv_passed != 0;
            }
          })
          .filter((x) => {
            if (!filterProcessed) {
              return true;
            }
            return this.processedInvoice(x);
          })
      );
    },
    async getQbyteInvoices(invoice) {
      const params = {
        invoice: invoice.join(','),
      };

      return this.$http
        .get('/accountant/qbyte/invoices/InovoiceTotal', { params })
        .then((res) =>
          res.data.response.reduce((acc, val) => {
            acc[val.invoice_id] = val;
            return acc;
          })
        );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    processedInvoice: (x) => ![7, 11, 21, 31, 41, 51].includes(x.inv_passed),

    async loadInvoices() {
      this.today = 'Export - ' + this.$moment(new Date()).format('YYYY-MM-DD');
      this.$session.set('type_search', this.type.type);
      this.$session.set('project_search', this.projectid);
      this.invoiceData = [];
      this.checkedRows = [];
      this.isLoading = true;
      try {
        let invType = null;
        let invPassed = null;
        let accInv = false;
        let wtsinv = false;
        let filterProcessed = false;
        if (
          this.type.type === 'AP Team Review' ||
          this.type.type === 'Unprocessed By PM' ||
          this.type.type === 'Unprocessed By WSS'
        ) {
          invType = 'unprocessed';
        } else if (
          this.type.type === 'Paid' ||
          this.type.type === 'Processed'
        ) {
          invType = 'processed';
        }
        if (this.type.type === 'Unprocessed By PM') {
          invPassed = true;
        } else if (this.type.type === 'Unprocessed By WSS') {
          invPassed = false;
        }
        if (
          this.type.type === 'Unprocessed By PM' ||
          this.type.type === 'Processed' ||
          this.type.type === 'Unprocessed By WSS' ||
          this.type.type === 'Not Ready For Payment' ||
          this.type.type === 'All'
        ) {
          wtsinv = true;
        }

        if (
          this.type.type === 'Paid' ||
          this.type.type === 'AP Team Review' ||
          this.type.type === 'All'
        ) {
          accInv = true;
        }

        if (
          this.type.type === 'All' ||
          this.type.type === 'Not Ready For Payment'
        ) {
          filterProcessed = true;
        }

        const projectid =
          this.projectid.projectid === 0 ? null : this.projectid.projectid;

        const pipeline = [];
        if (accInv) {
          pipeline.push(this.getAccountantInvoices(invType, projectid));
        }

        if (wtsinv) {
          pipeline.push(
            this.getWtsInvoices(invType, projectid, invPassed, filterProcessed)
          );
        }
        const result = await Promise.all(pipeline);
        this.invoiceData = result.reduce((acc, val) => {
          return [...acc, ...val];
        }, []);
        this.$refs.invoicetable.currentPage = this.$session.get('page');
        if (this.type.type === 'AP Team Review') {
          const invoiceId = this.invoiceData.map((x) => x.invoiceid);
          const qbyteInv = await this.getQbyteInvoices(invoiceId);
          this.invoiceData = this.invoiceData.map((inv) => {
            if (qbyteInv[inv.invoiceid] && qbyteInv[inv.invoiceid].total) {
              return {
                ...inv,
                total: qbyteInv[inv.invoiceid].total,
                quickpay_total: qbyteInv[inv.invoiceid].quickpay_total,
              };
            }
            return inv;
          });
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(':::', err);
      }
    },

    sendHistory(history) {
      this.history = history;
    },
    getFullFiltered() {
      var filtered = this.$refs.invoicetable.allFilteredData;

      return new Promise((resolve, reject) => {
        const promises = this.$refs.invoicetable.allFilteredData.map((record) =>
          this.$http.get(
            '/manager/get/invoice_services/' +
            record.projectid +
            '/' +
            record.invoiceid
          )
        );

        Promise.all(promises) // creates single promise that resolves when all `promises` resolve
          .then((responses) => {
            const dataArray = responses.map((response) => response.data.result);
            return resolve(dataArray);
          }) // resolves with an array of response data
          .catch(reject);
      });
    },
    seperateLines() {
      if (this.customAfe) {
        this.customLabel = [
          ['vcn', 'Vendor Company Name'],
          ['invnum', 'RTNest Invoice Number'],
          // ['ticketnum', 'Field Ticket Number'],
          ['vendor_invoice_number', 'Vendor Invoice Number'],
          ['creation_date', 'Invoice Date'],
          ['total', 'Total (Including GST)'],
          // ['tax_percentage', 'GST'],
          // ['quickpay_total', 'Quick Pay Total (Including GST)'],

          // ['gst_quickpay_total', 'Quick Pay GST'],
          ['inv_passed', 'Invoice Status'],
          ['qbyte_status', 'Qbyte Status'],
        ];
      }
      if (!this.customAfe) {
        this.customFiltered = [];

        const that = this;
        const filtered = this.sendFiltered();

        this.getFullFiltered()
          .then((data) => {
            this.customLabel.splice(
              1,
              0,
              ['afe', 'AFE Number'],
              ['percentage', 'AFE Percentage'],
              ['cc1', 'Cost Code 1'],
              ['cc2', 'Cost Code 2'],
              ['cc3', 'Cost Code 3']
            );

            for (var i = 0; i < data.length; i++) {
              var afes = data[i];

              afes.forEach(function (part, index) {
                var find = filtered.findIndex(
                  (h) => h.invoiceid == this[index].invoiceid
                );
                var filter = filtered.filter(
                  (o) => o.invoiceid === this[index].invoiceid
                );

                var cc3 = '';
                if (!this[index].ccthree_code.includes('---')) {
                  cc3 = this[index].ccthree_code;
                }

                var tempFiltered = {
                  invoiceid: filter[0].invoiceid,
                  vid: filter[0].vid,
                  companyid: filter[0].companyid,
                  projectid: filter[0].projectid,
                  invnum: filter[0].invnum,
                  inv_passed: filter[0].inv_passed,
                  tax_percentage: filter[0].tax_percentage,
                  discount: filter[0].discount,
                  vendor_note: filter[0].vendor_note,
                  wss_note: filter[0].wss_note,
                  wss_mdate: filter[0].wss_mdate,
                  wssid: filter[0].wssid,
                  wss_stat: filter[0].wss_stat,
                  wts_note: filter[0].wts_note,
                  wts_mdate: filter[0].wts_mdate,
                  wtsid: filter[0].wtsid,
                  wts_stat: filter[0].wts_stat,
                  creation_date: filter[0].creation_date,
                  vidcode: filter[0].vidcode,
                  bankaccount: filter[0].bankaccount,
                  vfname: filter[0].vfname,
                  vlname: filter[0].vlname,
                  gst: filter[0].gst,
                  rcn: filter[0].rcn,
                  vcn: filter[0].vcn,
                  wssfname: filter[0].wssfname,
                  wsslname: filter[0].wsslname,
                  wssphone_num: filter[0].wssphone_num,
                  wtsfname: filter[0].wtsfname,
                  wtslname: filter[0].wtslname,
                  wts_phone: filter[0].wts_phone,
                  wts_email: filter[0].wts_email,
                  mid1: filter[0].mid1,
                  mid2: filter[0].mid2,
                  mid3: filter[0].mid3,
                  mid4: filter[0].mid4,
                  mid5: filter[0].mid5,
                  mgr1_mdate: filter[0].mgr1_mdate,
                  mgr2_mdate: filter[0].mgr2_mdate,
                  mgr3_mdate: filter[0].mgr3_mdate,
                  mgr4_mdate: filter[0].mgr4_mdate,
                  mgr5_mdate: filter[0].mgr5_mdate,
                  mgr1_note: filter[0].mgr1_note,
                  mgr2_note: filter[0].mgr2_note,
                  mgr3_note: filter[0].mgr3_note,
                  mgr4_note: filter[0].mgr4_note,
                  mgr5_note: filter[0].mgr5_note,
                  ac_note: filter[0].ac_note,
                  mgr_stat_1: filter[0].mgr_stat_1,
                  mgr_stat_2: filter[0].mgr_stat_2,
                  mgr_stat_3: filter[0].mgr_stat_3,
                  mgr_stat_4: filter[0].mgr_stat_4,
                  mgr_stat_5: filter[0].mgr_stat_5,
                  accountant_paid: filter[0].accountant_paid,
                  accountant_mdate: filter[0].accountant_mdate,
                  province: filter[0].province,
                  city: filter[0].city,
                  addressline1: filter[0].addressline1,
                  addressline2: filter[0].addressline2,
                  postalcode: filter[0].postalcode,
                  company_province: filter[0].company_province,
                  company_city: filter[0].company_city,
                  company_addressline1: filter[0].company_addressline1,
                  company_addressline2: filter[0].company_addressline2,
                  company_postalcode: filter[0].company_postalcode,
                  phone_num: filter[0].phone_num,
                  fax_num: filter[0].fax_num,
                  ptn: filter[0].ptn,
                  projectname: filter[0].projectname,
                  endtday: filter[0].endtday,

                  tax_percentage: that.formatPrice(
                    filter[0].tax_percentage * (this[index].percentage / 100)
                  ),
                  total: that.formatPrice(
                    filter[0].total * (this[index].percentage / 100)
                  ),
                  // gst_quickpay_total: that.formatPrice(
                  //   filter[0].gst_quickpay_total *
                  //     (this[index].percentage / 100)
                  // ),
                  quickpay_total: that.formatPrice(
                    filter[0].quickpay_total * (this[index].percentage / 100)
                  ),

                  afe: this[index].afenum,
                  percentage: this[index].percentage,
                  cc1: this[index].ccone_code,
                  cc2: this[index].cctwo_code,
                  cc3: cc3,
                };

                that.customFiltered.push(tempFiltered);
              }, afes);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    sendFiltered() {
      try {
        const that = this;
        var filtered = this.$refs.invoicetable.allFilteredData.map(function (i) {
          i.tax_percentage = i.total - i.total / (i.tax_percentage / 100 + 1);

          if (i.quickpay_total < i.total) {
            i.gst_quickpay_total =
              i.quickpay_total -
              i.quickpay_total / (i.tax_percentage / 100 + 1);
          } else {
            i.quickpay_total = 0;
            i.gst_quickpay_total = 0;
          }
          return i;
        });

        return filtered;
      } catch (err) {
        console.log('sendFiltered err', err);
      }
    },
  },
  computed: {
    groupedServices: function () {
      const that = this;

      var groupedServices = this.lodash
        .chain(this.serviceData)
        .groupBy('vendor_company_name')
        .map((code, name) => ({ name, code }))
        .value();

      for (var i = 0; i < groupedServices.length; i++) {
        var groupedByName = this.lodash
          .chain(groupedServices[i].code)
          .groupBy('name')
          .map((services, name) => ({ name, services }))
          .value();
        groupedServices[i].code = groupedByName;

        var subtotal = 0;
        var estimateTotal = 0;
        var innerEstimateTotal = 0;

        for (var x = 0; x < groupedServices[i].code.length; x++) {
          groupedServices[i].code[x].total = groupedServices[i].code[
            x
          ].services.reduce(function (total, item) {
            return total + that.calcLineTotal(item);
          }, 0);

          subtotal += groupedServices[i].code[x].total;

          var groupedUom = this.lodash
            .chain(groupedServices[i].code[x].services)
            .groupBy('uom')
            .map((uom, name) => ({ name, uom }))
            .value();

          groupedServices[i].code[x].groupedUom = groupedUom;

          for (
            var y = 0;
            y < groupedServices[i].code[x].groupedUom.length;
            y++
          ) {
            groupedServices[i].code[x].groupedUom[y].uom.sort(function (a, b) {
              return new Date(b.end_date) - new Date(a.end_date);
            });

            groupedServices[i].code[x].groupedUom[y].latestPu =
              groupedServices[i].code[x].groupedUom[y].uom[0].pu;

            if (groupedServices[i].code[x].groupedUom[y].uom[0].estimate) {
              if (
                groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty1
              ) {
                groupedServices[i].code[x].groupedUom[y].estimateQuantity1 =
                  groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty1;
              }
              if (
                groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty2
              ) {
                groupedServices[i].code[x].groupedUom[y].estimateQuantity2 =
                  groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty2;
              }
              if (
                groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty3
              ) {
                groupedServices[i].code[x].groupedUom[y].estimateQuantity3 =
                  groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty3;
              }
            }

            groupedServices[i].code[x].groupedUom[y].total = groupedServices[
              i
            ].code[x].groupedUom[y].uom.reduce(function (total, item) {
              return total + that.calcLineTotal(item);
            }, 0);

            groupedServices[i].code[x].groupedUom[
              y
            ].totalQuantity = groupedServices[i].code[x].groupedUom[
              y
            ].uom.reduce(function (total, item) {
              return total + item.quantity;
            }, 0);

            var tec = 0;
            var tac = 0;
            var tacPrevious = 0;
            var taq = 0;
            var taqPrevious = 0;
            var condition = true;

            if (groupedServices[i].code[x].groupedUom[y].uom[0].estimate) {
              var teq = 0;
              if (y == 0) {
                teq =
                  groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty1;
              }
              if (y == 1) {
                teq =
                  groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty2;
              }
              if (y == 2) {
                teq =
                  groupedServices[i].code[x].groupedUom[y].uom[0].estimate.qty3;
              }
              for (
                var n = 0;
                n < groupedServices[i].code[x].groupedUom[y].uom.length;
                n++
              ) {
                var line = groupedServices[i].code[x].groupedUom[y].uom[n];
                tec = 0;
                var pu = line.pu;
                var disc = line.discount;
                var aq = line.quantity;
                taq += aq;
                var ac = aq * pu * (1 - disc / 100);
                tac += ac;
                if (n == 0 && taq > teq && condition) {
                  tec = teq * pu;
                  condition = false;
                }
                if (n >= 0 && taq <= teq && condition) {
                  tec = tac + (teq - taq) * pu;
                }
                if (n > 0 && taq > teq && condition) {
                  condition = false;
                  tec = tacPrevious + (teq - taqPrevious) * pu;
                }
                if (!condition) {
                  break;
                }
                tacPrevious = tac;
                taqPrevious = taq;
              }
            }

            if (y == 0) {
              groupedServices[i].code[x].groupedUom[y].totalEstimateCost1 = tec;
              innerEstimateTotal += tec;
            }
            if (y == 1) {
              groupedServices[i].code[x].groupedUom[y].totalEstimateCost2 = tec;
              innerEstimateTotal += tec;
            }
            if (y == 2) {
              groupedServices[i].code[x].groupedUom[y].totalEstimateCost3 = tec;
              innerEstimateTotal += tec;
            }
          }
        }

        groupedServices[i].subtotal = subtotal;
        groupedServices[i].estimate = innerEstimateTotal;
      }

      return groupedServices;
    },
    customLabels: function () {
      var custom = {};
      for (var i = 0; i < this.customLabel.length; i++) {
        custom[this.customLabel[i][0]] = { title: this.customLabel[i][1] };
      }
      return custom;
    },
    subtotal: function () {
      const that = this;
      return this.checkedRows.reduce(function (total, item) {
        var invoice_total = item.total;

        if (
          item.quickpay_total > 0 &&
          that.$moment(item.endtday) > that.$moment()
        ) {
          invoice_total = item.quickpay_total;
        }

        return total + invoice_total;
      }, 0);
    },
    bankaccounts: function () {
      const that = this;
      return this.lodash.uniqBy(this.checkedRows, 'vcn');
    },
    totalActualCost: function () {
      // for (var i = 0; i < groupedServices.length; i++) {
      //   var total = 0;
      //
      //   for (var x = 0; x < groupedServices[i].code.length; x++) {
      //
      //   }
      // }
    },
    totalEstimateTotal: function () { },
  },
  mounted() {
    this.$axios
      .get('wts/get/projects')
      .then((response) => {
        this.projectData = response.data.result;
        this.projectData.push({ projectid: 0, projectname: 'All' });
        if (!this.$session.get('project_search')) {
          this.projectid = { projectid: 0, projectname: 'All' };
        } else {
          this.projectid = this.$session.get('project_search');
        }
        // if (response.data.result.length > 0) {
        //   this.projectidSearch = response.data.result[0].projectid;
        // }
        if (!this.$session.get('type_search')) {
          this.type = {
            type: 'Not Ready For Payment',
            id: 'not_ready_for_payment',
          };
        } else {
          this.type['type'] = this.$session.get('type_search');
        }
        this.loadInvoices();
      })
      .catch((error) => { });

    this.$http.get('manager/get/profile').then((response) => {
      this.usersName = response.data.fname + ' ' + response.data.lname;
    });
  },
};
</script>

<style lang="scss">
.acc-rt-datepicker {
  input {
    width: 100%;
  }
}

.acc-table {

  th:first-child,
  td:first-child {
    width: 50px;
  }

  overflow-x: hidden !important;
}

.VueTables__pagination-wrapper {
  display: block !important;
}

@media screen and (max-width: 767px) {
  .acc-table {

    th:nth-child(4),
    td:nth-child(4),
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7),
    th:nth-child(3),
    td:nth-child(3) {
      display: none !important;
    }

    td,
    th {
      width: 50%;
    }
  }
}

#selectAllCheckbox {
  display: block;
  margin: 0 auto;
}

.red {
  color: #db1616;
}

.green {
  color: #4dbd74;
}

.invoice-report {
  width: 100%;

  button {
    width: 100%;
  }
}
</style>
