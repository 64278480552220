var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        [
          _c("h4", [
            _c("i", { staticClass: "fa fa-search" }),
            _vm._v(" Search Invoice")
          ]),
          _c("hr"),
          _c(
            "b-collapse",
            {
              attrs: { id: "searchCollapse" },
              model: {
                value: _vm.searchContainer,
                callback: function($$v) {
                  _vm.searchContainer = $$v
                },
                expression: "searchContainer"
              }
            },
            [
              _c(
                "b-row",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("RTNest Invoice Ref. Number")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "tracking",
                              placeholder: "RTNest Invoice Ref. Number"
                            },
                            model: {
                              value: _vm.invnum,
                              callback: function($$v) {
                                _vm.invnum = $$v
                              },
                              expression: "invnum"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Project")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.projectidSearch,
                            expression: "projectidSearch"
                          }
                        ],
                        staticClass: "select-account form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.projectidSearch = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.projectData, function(option) {
                        return _c(
                          "option",
                          { domProps: { value: option.projectid } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(option.projectname) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Vendor Company Name")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "vendor",
                              placeholder: "Vendor Company Name"
                            },
                            model: {
                              value: _vm.vendor_company_name,
                              callback: function($$v) {
                                _vm.vendor_company_name = $$v
                              },
                              expression: "vendor_company_name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("AFE / PO Number")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "afe",
                              placeholder: "AFE Number"
                            },
                            model: {
                              value: _vm.afe,
                              callback: function($$v) {
                                _vm.afe = $$v
                              },
                              expression: "afe"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Cost Code #1 / Major")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "cc1",
                              placeholder: "Cost Code 1"
                            },
                            model: {
                              value: _vm.cc1,
                              callback: function($$v) {
                                _vm.cc1 = $$v
                              },
                              expression: "cc1"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Cost Code #2 / Minor")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "cc2",
                              placeholder: "Cost Code 2"
                            },
                            model: {
                              value: _vm.cc2,
                              callback: function($$v) {
                                _vm.cc2 = $$v
                              },
                              expression: "cc2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Cost Code #3 / Description")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "cc3",
                              placeholder: "Cost Code 3"
                            },
                            model: {
                              value: _vm.cc3,
                              callback: function($$v) {
                                _vm.cc3 = $$v
                              },
                              expression: "cc3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Invoice Starting Date - Today")
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("datepicker", {
                            staticClass: "date-picker rt-datepicker",
                            attrs: { placeholder: "Start Date" },
                            model: {
                              value: _vm.date,
                              callback: function($$v) {
                                _vm.date = $$v
                              },
                              expression: "date"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-3",
                              attrs: { variant: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [
                              _vm._v("\n                Search "),
                              _c("i", { staticClass: "fa fa-search" })
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.clear()
                                }
                              }
                            },
                            [
                              _vm._v("\n                Clear "),
                              _c("i", { staticClass: "fa fa-times" })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "b-btn",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.searchCollapse",
                          modifiers: { searchCollapse: true }
                        }
                      ],
                      staticClass: "mt-1 btn-blue"
                    },
                    [
                      _c("span", { staticClass: "when-opened" }, [
                        _vm._v("Close Advanced Search "),
                        _c("i", { staticClass: "fa fa-chevron-up" })
                      ]),
                      _c("span", { staticClass: "when-closed" }, [
                        _vm._v("Open Advanced Search "),
                        _c("i", { staticClass: "fa fa-chevron-down" })
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c("h4", { staticClass: "d-inline" }, [
            _c("i", { staticClass: "nav-icon icon-layers" }),
            _vm._v(" Invoices")
          ]),
          _c("hr", { staticClass: "clearfix my-3" }),
          _c("div", { staticClass: "row mb-3" }, [
            _c(
              "div",
              { staticClass: "col-md-4 mb-2" },
              [
                _c("h5", [_vm._v("Select Project To Get Invoices")]),
                _c("v-select", {
                  staticClass: "project-select",
                  attrs: {
                    clearable: false,
                    label: "projectname",
                    options: _vm.projectData
                  },
                  on: {
                    input: function($event) {
                      return _vm.loadInvoices()
                    }
                  },
                  model: {
                    value: _vm.projectid,
                    callback: function($$v) {
                      _vm.projectid = $$v
                    },
                    expression: "projectid"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-9 col-md-6" },
              [
                _c("h5", [_vm._v("AP Team Review / Paid")]),
                _c("v-select", {
                  staticClass: "project-select",
                  attrs: {
                    clearable: false,
                    label: "type",
                    options: _vm.typeOptions
                  },
                  on: {
                    input: function($event) {
                      return _vm.loadInvoices()
                    }
                  },
                  model: {
                    value: _vm.type,
                    callback: function($$v) {
                      _vm.type = $$v
                    },
                    expression: "type"
                  }
                })
              ],
              1
            ),
            _vm.type
              ? _c(
                  "div",
                  { staticClass: "col-3 col-md-4" },
                  [
                    _c("h5", [_vm._v(" ")]),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.export",
                            modifiers: { export: true }
                          }
                        ],
                        staticClass: "btn btn-primary mr-2",
                        on: {
                          click: function($event) {
                            return _vm.clearLabels()
                          }
                        }
                      },
                      [
                        _vm._v("\n            Export Invoice Report "),
                        _c("i", { staticClass: "fa fa-cog" })
                      ]
                    ),
                    _c(
                      "b-overlay",
                      {
                        staticClass: "d-inline-block",
                        attrs: {
                          show: _vm.isLoading,
                          rounded: "",
                          opacity: "0.6",
                          "spinner-small": "",
                          "spinner-variant": "primary"
                        }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.loadInvoices()
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-sync-alt" })]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "row mt-4 mb-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-modal",
                  {
                    ref: "export-invoice-report",
                    attrs: {
                      "hide-footer": "",
                      id: "export",
                      title: "Export CSV Options",
                      "title-tag": "h2",
                      centered: "",
                      size: "lg"
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("h4", [_vm._v("Vendor")]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["vcn", "Vendor Company Name"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "vcn",
                                    "Vendor Company Name"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["vcn", "Vendor Company Name"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _c("strong", [_vm._v("Company Name")])
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["addressline1", "Vendor Address Line 1"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "addressline1",
                                    "Vendor Address Line 1"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = [
                                      "addressline1",
                                      "Vendor Address Line 1"
                                    ],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Address Line 1")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["addressline2", "Vendor Address Line 2"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "addressline2",
                                    "Vendor Address Line 2"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = [
                                      "addressline2",
                                      "Vendor Address Line 2"
                                    ],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Address Line 2")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["city", "City"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, ["city", "City"]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["city", "City"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("City")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["province", "Province"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "province",
                                    "Province"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["province", "Province"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Province")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["postalcode", "Postal Code"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "postalcode",
                                    "Postal Code"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["postalcode", "Postal Code"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Postal Code")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["phone_num", "Phone Number"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "phone_num",
                                    "Phone Number"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["phone_num", "Phone Number"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Phone Number")
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("h4", [_vm._v("Invoice")]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["invnum", "RTNest Invoice Number"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "invnum",
                                    "RTNest Invoice Number"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["invnum", "RTNest Invoice Number"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("RTNest Invoice Number")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["inv_passed", "Invoice Status"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "inv_passed",
                                    "Invoice Status"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["inv_passed", "Invoice Status"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Invoice Status")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["ticketnum", "Field Ticket Number"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "ticketnum",
                                    "Field Ticket Number"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = [
                                      "ticketnum",
                                      "Field Ticket Number"
                                    ],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Field Ticket Number")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: [
                                "vendor_invoice_number",
                                "Vendor Invoice Number"
                              ],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "vendor_invoice_number",
                                    "Vendor Invoice Number"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = [
                                      "vendor_invoice_number",
                                      "Vendor Invoice Number"
                                    ],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Vendor Invoice Number")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["creation_date", "Invoice Date"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "creation_date",
                                    "Invoice Date"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["creation_date", "Invoice Date"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Invoice Date")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["total", "Total (Including GST)"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "total",
                                    "Total (Including GST)"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["total", "Total (Including GST)"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Total (Including GST)")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["tax_percentage", "GST"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "tax_percentage",
                                    "GST"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["tax_percentage", "GST"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("GST")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: [
                                "quickpay_total",
                                "Quick Pay Total (Including GST)"
                              ],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "quickpay_total",
                                    "Quick Pay Total (Including GST)"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = [
                                      "quickpay_total",
                                      "Quick Pay Total (Including GST)"
                                    ],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Quick Pay Total (Including GST)")
                          ])
                        ]),
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLabel,
                                expression: "customLabel"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: ["qbyte_status", "Qbyte Status"],
                              checked: Array.isArray(_vm.customLabel)
                                ? _vm._i(_vm.customLabel, [
                                    "qbyte_status",
                                    "Qbyte Status"
                                  ]) > -1
                                : _vm.customLabel
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.customLabel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = ["qbyte_status", "Qbyte Status"],
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.customLabel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.customLabel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.customLabel = $$c
                                }
                              }
                            }
                          }),
                          _c("label", { staticClass: "form-check-label" }, [
                            _vm._v("Qbyte Status")
                          ])
                        ])
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "invoice-report mt-3" },
                      [
                        _c("h5", [_vm._v(" ")]),
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              show: _vm.emailLoading,
                              rounded: "",
                              opacity: "0.6",
                              "spinner-small": "",
                              "spinner-variant": "primary"
                            }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-sm",
                                on: {
                                  click: function($event) {
                                    return _vm.getWeeklyReport(_vm.customLabel)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.emailLoading
                                        ? "Downloading Invoice Report..."
                                        : "Download Invoice Report"
                                    ) +
                                    "\n                  "
                                ),
                                _c("i", { staticClass: "fa fa-download mr-1" })
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm.checkedRows.length > 0
            ? _c(
                "div",
                {
                  staticClass: "row mb-3",
                  staticStyle: {
                    background: "#5d5d5d",
                    "border-radius": "4px",
                    padding: "1em",
                    "margin-right": "0 !important",
                    "margin-left": "0 !important"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-12", staticStyle: { color: "#fff" } },
                    [
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            color: "rgb(255, 255, 255)",
                            "margin-top": "5px",
                            "margin-bottom": "0"
                          }
                        },
                        [
                          _vm._v(
                            "\n            Selected Total (GST Included): $" +
                              _vm._s(_vm.formatPrice(_vm.subtotal)) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm.bankaccounts.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "col-12",
                          staticStyle: { color: "#fff" }
                        },
                        [
                          _c("h5", { staticClass: "mt-2 mb-0" }, [
                            _vm._v("Bank Accounts:")
                          ]),
                          _vm._l(_vm.bankaccounts, function(vendor) {
                            return _c("p", { staticClass: "mb-1 mt-0" }, [
                              _c("strong", [_vm._v(_vm._s(vendor.vcn))]),
                              _vm._v(
                                ": " +
                                  _vm._s(vendor.bankaccount) +
                                  "\n          "
                              )
                            ])
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 col-lg-6 mt-3 mb-2" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info btn-lg",
                          staticStyle: {
                            background: "#b731ea",
                            "border-color": "#b731ea",
                            color: "#fff"
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.loadServiceEstimates()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-binoculars" }),
                          _vm._v(
                            " Review Line-item Estimate Costs vs\n            Actual Cost Per AFE\n          "
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _c("v-client-table", {
            ref: "invoicetable",
            staticClass: "inv-status-table table-responsive acc-table",
            attrs: {
              id: "dataTable",
              columns: _vm.columns,
              data: _vm.invoiceData,
              options: _vm.options,
              theme: _vm.theme
            },
            on: {
              filter: function($event) {
                return _vm.clearSelected()
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "total",
                  fn: function(props) {
                    return _c("span", {}, [
                      _vm._v(
                        "\n          $" +
                          _vm._s(_vm.formatPrice(props.row.total)) +
                          "\n        "
                      )
                    ])
                  }
                },
                {
                  key: "quickpay_total",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.quickpay_total > 0 &&
                      _vm.$moment(props.row.endtday) > _vm.$moment()
                        ? _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.formatPrice(props.row.quickpay_total)
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ])
                  }
                },
                {
                  key: "invoice",
                  fn: function(props) {
                    return _c("span", {}, [
                      _c("a", { attrs: { href: "/#/wss/invoice" } }, [
                        _vm._v(_vm._s(props.row.invoice))
                      ]),
                      _vm._v(" (" + _vm._s(props.row.company) + ")")
                    ])
                  }
                },
                {
                  key: "check",
                  fn: function(props) {
                    return props.row.accountant_paid === 0
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.checkedRows,
                              expression: "checkedRows"
                            }
                          ],
                          staticClass: "checkedInvoices rt-checkbox-table",
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: {
                              id: props.row.invoiceid,
                              projectid: props.row.projectid,
                              quickpay_total: props.row.quickpay_total,
                              total: props.row.total,
                              endtday: props.row.endtday,
                              bankaccount: props.row.bankaccount,
                              vcn: props.row.vcn
                            },
                            checked: Array.isArray(_vm.checkedRows)
                              ? _vm._i(_vm.checkedRows, {
                                  id: props.row.invoiceid,
                                  projectid: props.row.projectid,
                                  quickpay_total: props.row.quickpay_total,
                                  total: props.row.total,
                                  endtday: props.row.endtday,
                                  bankaccount: props.row.bankaccount,
                                  vcn: props.row.vcn
                                }) > -1
                              : _vm.checkedRows
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.checkedRows,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = {
                                    id: props.row.invoiceid,
                                    projectid: props.row.projectid,
                                    quickpay_total: props.row.quickpay_total,
                                    total: props.row.total,
                                    endtday: props.row.endtday,
                                    bankaccount: props.row.bankaccount,
                                    vcn: props.row.vcn
                                  },
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.checkedRows = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkedRows = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkedRows = $$c
                              }
                            }
                          }
                        })
                      : _vm._e()
                  }
                },
                {
                  key: "inv_passed",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.accountant_paid === 0
                        ? _c("div", [
                            props.row.inv_passed === 9
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By WSS (Submit Again)\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 17
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Accontant\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 8 &&
                            props.row.acc_project != 1
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-sm btn-warning btn-pm"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(
                                        " Pending By Project Manager\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 8 &&
                            props.row.acc_project == 1
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-sm btn-warning btn-pm",
                                      staticStyle: {
                                        "border-color": "#24cabf !important",
                                        background: "#24cabf !important"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(
                                        " Pending For First Approval\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 7
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review\n              ")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 6
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-sm btn-warning btn-management"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(
                                        " Pending By Higher Management\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 5
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Project Manager (Pending\n                Vendor)\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 0
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-warning" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(" Pending By WSS\n              ")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 11
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review\n              ")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 13
                              ? _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-sm btn-warning btn-management"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(
                                        " Pending By Higher Management\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 14
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending\n                Vendor)\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 21
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review\n              ")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 23
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-warning" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(
                                        " Pending By Management\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 24
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending\n                Vendor)\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 31
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review\n              ")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 33
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-warning" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(
                                        " Pending By Management\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 34
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending\n                Vendor)\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 41
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review\n              ")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 43
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-warning" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-question"
                                      }),
                                      _vm._v(
                                        " Pending By Management\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 44
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending\n                Vendor)\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 51
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-success" },
                                    [
                                      _c("i", { staticClass: "fa fa-check" }),
                                      _vm._v(" AP Team Review\n              ")
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            props.row.inv_passed === 54
                              ? _c("div", [
                                  _c(
                                    "button",
                                    { staticClass: "btn-sm btn-danger" },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(
                                        " Rejected By Management (Pending\n                Vendor)\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      props.row.accountant_paid === 1
                        ? _c("div", [
                            _c(
                              "button",
                              { staticClass: "btn-sm btn-success" },
                              [
                                _c("i", { staticClass: "fa fa-check" }),
                                _vm._v(" Paid\n            ")
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  }
                },
                {
                  key: "creation_date",
                  fn: function(props) {
                    return _c("div", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm
                              .$moment(props.row.creation_date)
                              .format("DD/MM/YYYY")
                          ) +
                          "\n        "
                      )
                    ])
                  }
                },
                {
                  key: "vfname",
                  fn: function(props) {
                    return _c("div", {}, [
                      _vm._v(
                        "\n          " +
                          _vm._s(props.row.vfname) +
                          " " +
                          _vm._s(props.row.vlname) +
                          "\n        "
                      )
                    ])
                  }
                },
                {
                  key: "invnum",
                  fn: function(props) {
                    return _c("div", {}, [
                      props.row.inv_passed == 7 ||
                      props.row.accountant_paid === 1 ||
                      props.row.inv_passed == 11 ||
                      props.row.inv_passed == 6 ||
                      props.row.inv_passed == 21 ||
                      props.row.inv_passed == 31 ||
                      props.row.inv_passed == 41 ||
                      props.row.inv_passed == 51
                        ? _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.setTheInvoice(props.row, 2)
                                }
                              }
                            },
                            [_vm._v(_vm._s(props.row.invnum))]
                          )
                        : _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.setTheInvoice(props.row, 1)
                                }
                              }
                            },
                            [_vm._v(_vm._s(props.row.invnum))]
                          )
                    ])
                  }
                },
                {
                  key: "actions",
                  fn: function(props) {
                    return _c("div", {}, [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.history",
                              modifiers: { history: true }
                            }
                          ],
                          staticClass: "btn white btn-sm btn-secondary",
                          on: {
                            click: function($event) {
                              return _vm.sendHistory(props.row)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-history" })]
                      )
                    ])
                  }
                }
              ],
              null,
              true
            )
          }),
          _c(
            "b-modal",
            {
              ref: "history",
              attrs: {
                "ok-only": "",
                id: "history",
                title: "Invoice #" + _vm.history.invnum,
                "title-tag": "h2",
                centered: "",
                size: "xl"
              }
            },
            [
              _c("InvoiceHistory", {
                attrs: { history: _vm.history, isMounted: true }
              })
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "serviceEstimates",
              attrs: {
                "ok-only": "",
                id: "serviceEstimates",
                title: "Service Estimates",
                "title-tag": "h2",
                centered: "",
                size: "xl"
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 col-xl-6 mt-1" },
                  [
                    _c("h4", [_vm._v("Select AFE / PO")]),
                    _c("multiselect", {
                      attrs: {
                        placeholder: "Search or add afe / po",
                        "tag-placeholder": "Search or add afe / po",
                        "track-by": "afenum",
                        options: _vm.afesList,
                        multiple: false,
                        "close-on-select": true,
                        "clear-on-select": false,
                        "preserve-search": true,
                        taggable: false,
                        label: "afenum",
                        "preselect-first": true
                      },
                      on: {
                        input: function($event) {
                          return _vm.getServices()
                        }
                      },
                      model: {
                        value: _vm.afe,
                        callback: function($$v) {
                          _vm.afe = $$v
                        },
                        expression: "afe"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._l(_vm.groupedServices, function(vendor) {
                return _c("div", { staticClass: "col-12 my-4" }, [
                  _c("h3", [_vm._v(_vm._s(vendor.name))]),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                "vertical-align": "middle",
                                "font-size": "1.8em"
                              }
                            },
                            [_c("strong", [_vm._v("Totals")])]
                          ),
                          _c("td"),
                          _c("td"),
                          _c("td"),
                          _c("td", [
                            _c("strong", [_vm._v("Estimate Total:")]),
                            _c("br"),
                            _vm._v(
                              "$" +
                                _vm._s(_vm.formatPrice(vendor.estimate)) +
                                "\n                  "
                            )
                          ]),
                          _c("td", [
                            _c("strong", [_vm._v("Actual Total:")]),
                            _c("br"),
                            _vm._v(
                              "$" +
                                _vm._s(_vm.formatPrice(vendor.subtotal)) +
                                "\n                  "
                            )
                          ]),
                          _c("td", [
                            _c("strong", [_vm._v("Total Net:")]),
                            _c("br"),
                            _c(
                              "span",
                              {
                                class: {
                                  green: vendor.estimate > vendor.subtotal,
                                  red: vendor.estimate < vendor.subtotal
                                }
                              },
                              [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatPrice(
                                        vendor.estimate - vendor.subtotal
                                      )
                                    )
                                )
                              ]
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Service Name")]),
                          _c("th", [_vm._v("UOM 1")]),
                          _c("th", [_vm._v("UOM 2")]),
                          _c("th", [_vm._v("UOM 3")]),
                          _c("th", [_vm._v("Total Actual Estimate")]),
                          _c("th", [_vm._v("Total Actual Cost")]),
                          _c("th", [_vm._v("Net")])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(vendor.code, function(service) {
                          return _c("tr", [
                            _c("td", [_vm._v(_vm._s(service.name))]),
                            _c("td", [
                              _c("strong", [_vm._v("Total Actual Quantity")]),
                              _vm._v(
                                ":\n                    " +
                                  _vm._s(service.groupedUom[0].totalQuantity)
                              ),
                              _c("br"),
                              _c("strong", [_vm._v("Latest P/U")]),
                              _vm._v(
                                ": $" +
                                  _vm._s(service.groupedUom[0].latestPu) +
                                  "\n                    (" +
                                  _vm._s(service.groupedUom[0].name) +
                                  ")"
                              ),
                              _c("br"),
                              service.groupedUom[0].estimateQuantity1
                                ? _c("div", [
                                    _c(
                                      "strong",
                                      { staticClass: "my-2 d-block" },
                                      [_vm._v("Total Estimate Quantity:")]
                                    ),
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.formatPrice(
                                            service.groupedUom[0]
                                              .estimateQuantity1
                                          )
                                        ) +
                                        "\n                      "
                                    ),
                                    _c(
                                      "strong",
                                      { staticClass: "my-2 d-block" },
                                      [_vm._v("Total Estimate Cost:")]
                                    ),
                                    _vm._v(
                                      "\n                      $" +
                                        _vm._s(
                                          _vm.formatPrice(
                                            service.groupedUom[0]
                                              .totalEstimateCost1
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                                : _c("div", [_vm._v("$0.00")])
                            ]),
                            service.groupedUom[1]
                              ? _c("td", [
                                  _c("strong", [
                                    _vm._v("Total Actual Quantity")
                                  ]),
                                  _vm._v(
                                    ":\n                    " +
                                      _vm._s(
                                        service.groupedUom[1].totalQuantity
                                      )
                                  ),
                                  _c("br"),
                                  _c("strong", [_vm._v("Latest P/U")]),
                                  _vm._v(
                                    ": $" +
                                      _vm._s(service.groupedUom[1].latestPu) +
                                      "\n                    (" +
                                      _vm._s(service.groupedUom[1].name) +
                                      ")"
                                  ),
                                  _c("br"),
                                  service.groupedUom[1].estimateQuantity2
                                    ? _c("div", [
                                        _c(
                                          "strong",
                                          { staticClass: "my-2 d-block" },
                                          [_vm._v("Total Estimate Quantity:")]
                                        ),
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                service.groupedUom[1]
                                                  .estimateQuantity2
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                        _c(
                                          "strong",
                                          { staticClass: "my-2 d-block" },
                                          [_vm._v("Total Estimate Cost:")]
                                        ),
                                        _vm._v(
                                          "\n                      $" +
                                            _vm._s(
                                              _vm.formatPrice(
                                                service.groupedUom[1]
                                                  .totalEstimateCost2
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    : _c("div", [_vm._v("$0.00")])
                                ])
                              : _c("td", [_vm._v("N/A")]),
                            service.groupedUom[2]
                              ? _c("td", [
                                  _c("strong", [
                                    _vm._v("Total Actual Quantity")
                                  ]),
                                  _vm._v(
                                    ":\n                    " +
                                      _vm._s(
                                        service.groupedUom[2].totalQuantity
                                      )
                                  ),
                                  _c("br"),
                                  _c("strong", [_vm._v("Latest P/U")]),
                                  _vm._v(
                                    ": $" +
                                      _vm._s(service.groupedUom[2].latestPu) +
                                      "\n                    (" +
                                      _vm._s(service.groupedUom[2].name) +
                                      ")"
                                  ),
                                  _c("br"),
                                  service.groupedUom[2].estimateQuantity3
                                    ? _c("div", [
                                        _c(
                                          "strong",
                                          { staticClass: "my-2 d-block" },
                                          [_vm._v("Total Estimate Quantity:")]
                                        ),
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                service.groupedUom[2]
                                                  .estimateQuantity3
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                        _c(
                                          "strong",
                                          { staticClass: "my-2 d-block" },
                                          [_vm._v("Total Estimate Cost:")]
                                        ),
                                        _vm._v(
                                          "\n                      $" +
                                            _vm._s(
                                              _vm.formatPrice(
                                                service.groupedUom[2]
                                                  .totalEstimateCost3
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    : _c("div", [_vm._v("$0.00")])
                                ])
                              : _c("td", [_vm._v("N/A")]),
                            _c("td", [
                              service.groupedUom[0] &&
                              service.groupedUom[0].estimateQuantity1 &&
                              service.groupedUom[1] &&
                              service.groupedUom[1].estimateQuantity1 &&
                              !service.groupedUom[2]
                                ? _c("div", [
                                    _vm._v(
                                      "\n                      $" +
                                        _vm._s(
                                          _vm.formatPrice(
                                            service.groupedUom[0].latestPu *
                                              service.groupedUom[0]
                                                .estimateQuantity1 +
                                              service.groupedUom[1].latestPu *
                                                service.groupedUom[1]
                                                  .estimateQuantity2
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                                : _vm._e(),
                              service.groupedUom[1] && service.groupedUom[2]
                                ? _c("div", [
                                    _vm._v(
                                      "\n                      $" +
                                        _vm._s(
                                          _vm.formatPrice(
                                            service.groupedUom[0].latestPu *
                                              service.groupedUom[0]
                                                .estimateQuantity1 +
                                              service.groupedUom[1].latestPu *
                                                service.groupedUom[1]
                                                  .estimateQuantity2 +
                                              service.groupedUom[2].latestPu *
                                                service.groupedUom[2]
                                                  .estimateQuantity3
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                                : _vm._e(),
                              service.groupedUom[0] &&
                              service.groupedUom[0].estimateQuantity1 &&
                              !service.groupedUom[1] &&
                              !service.groupedUom[2]
                                ? _c("div", [
                                    _vm._v(
                                      "\n                      $" +
                                        _vm._s(
                                          _vm.formatPrice(
                                            service.groupedUom[0].latestPu *
                                              service.groupedUom[0]
                                                .estimateQuantity1
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              _vm._v(
                                "$" + _vm._s(_vm.formatPrice(service.total))
                              )
                            ]),
                            _c("td", [
                              service.groupedUom[0] &&
                              !service.groupedUom[0].estimateQuantity1
                                ? _c("div", [
                                    _vm._v(
                                      "\n                      $0.00\n                    "
                                    )
                                  ])
                                : _c("div", [
                                    service.groupedUom[1] &&
                                    !service.groupedUom[2]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "green",
                                            class: {
                                              danger:
                                                service.groupedUom[0].latestPu *
                                                  service.groupedUom[0]
                                                    .estimateQuantity1 +
                                                  service.groupedUom[1]
                                                    .latestPu *
                                                    service.groupedUom[1]
                                                      .estimateQuantity2 -
                                                  service.total <
                                                0
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        $" +
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    service.groupedUom[0]
                                                      .latestPu *
                                                      service.groupedUom[0]
                                                        .estimateQuantity1 +
                                                      service.groupedUom[1]
                                                        .latestPu *
                                                        service.groupedUom[1]
                                                          .estimateQuantity2 -
                                                      service.total
                                                  )
                                                ) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    service.groupedUom[1] &&
                                    service.groupedUom[2]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "green",
                                            class: {
                                              danger:
                                                service.groupedUom[0].latestPu *
                                                  service.groupedUom[0]
                                                    .estimateQuantity1 +
                                                  service.groupedUom[1]
                                                    .latestPu *
                                                    service.groupedUom[1]
                                                      .estimateQuantity2 +
                                                  service.groupedUom[2]
                                                    .latestPu *
                                                    service.groupedUom[2]
                                                      .estimateQuantity3 -
                                                  service.total <
                                                0
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        $" +
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    service.groupedUom[0]
                                                      .latestPu *
                                                      service.groupedUom[0]
                                                        .estimateQuantity1 +
                                                      service.groupedUom[1]
                                                        .latestPu *
                                                        service.groupedUom[1]
                                                          .estimateQuantity2 +
                                                      service.groupedUom[2]
                                                        .latestPu *
                                                        service.groupedUom[2]
                                                          .estimateQuantity3 -
                                                      service.total
                                                  )
                                                ) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    service.groupedUom[0] &&
                                    service.groupedUom[0].estimateQuantity1 &&
                                    !service.groupedUom[1] &&
                                    !service.groupedUom[2]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "green",
                                            class: {
                                              danger:
                                                service.groupedUom[0].latestPu *
                                                  service.groupedUom[0]
                                                    .estimateQuantity1 -
                                                  service.total <
                                                0
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        $" +
                                                _vm._s(
                                                  _vm.formatPrice(
                                                    service.groupedUom[0]
                                                      .latestPu *
                                                      service.groupedUom[0]
                                                        .estimateQuantity1 -
                                                      service.total
                                                  )
                                                ) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ])
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }